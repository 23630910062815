import { render, staticRenderFns } from "./showDetails.vue?vue&type=template&id=06045e88&scoped=true&"
import script from "./showDetails.vue?vue&type=script&lang=js&"
export * from "./showDetails.vue?vue&type=script&lang=js&"
import style0 from "./showDetails.vue?vue&type=style&index=0&id=06045e88&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06045e88",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/1551980-cci-46937945-950616/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06045e88')) {
      api.createRecord('06045e88', component.options)
    } else {
      api.reload('06045e88', component.options)
    }
    module.hot.accept("./showDetails.vue?vue&type=template&id=06045e88&scoped=true&", function () {
      api.rerender('06045e88', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projectBus/schedulingPlan/projectMange/components/showDetails.vue"
export default component.exports